@charset 'utf-8';
@import url('https://cdn.jsdelivr.net/npm/foundation-sites@6.7.3/dist/css/foundation.min.css');

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

$font-body-family: 'Assistant', sans-serif;
$font-body-style: normal;
$font-body-weight: 400;
$font-body-weight-bold: 700;
$font-heading-family: 'Assistant', sans-serif;
$font-heading-style: normal;
$font-heading-weight: 400;
$font-body-scale: 1.1;

$font-family: 'Assistant', sans-serif;
$font-style: normal;
$font-weight: 400;

body {
  font-family: $font-family;
  font-style: $font-style;
  font-weight: $font-weight;
  letter-spacing: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
{
  font-family: $font-family;
}


.page-width {
  margin: 0 auto;
  padding: 0 1.5rem;
  max-width: 1344px;
  
  @media screen and (min-width: 750px) { padding: 0 5rem;}
}


.custom-padding {
  padding-top: 9%; 

  @media screen and (min-width: 640px) and (max-width: 1300px) { padding-top: 15%;}
  @media screen and (max-width: 39.9375em) {padding-top: 25%;}
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  display: none;
  z-index: -1;
}


@media screen and (max-width: 335px) {
  .homepage, .contactus, .about , .people , .gallery-page {
    padding-top: 19%;
  }
}

@media screen and  (min-width: 335px)  and (max-width: 639px) {
  .homepage, .about  {
    padding-top: 13%;
  }
}

@media screen and  (min-width: 639px)  and (max-width: 880px) {
  .homepage, .about  {
    padding-top: 8%;
  }
}


@media screen and (min-width: 880px) {
  .homepage , .about , .salvation {
    padding-top: 8%;
  }
}




.nav-bar  {

  position: fixed;
  width: 100%;
  z-index: 1;

  $highlight-color: #767676;
  $nav-padding: 20px;
  $nav-text-color: $dark-gray;
  $nav-bg-color: white;
  $menu-text-color: #121212; 
  $initialMargin: 15.5%;
  $baseScreenWidth: 1920px;
  $marginReductionRate: 0.5%;

  font-family: $font-body-family;
  font-style: $font-body-style;
  font-weight: $font-body-weight;


  #offCanvassearch-small, #offCanvassearch-large {
    background: white;
    
  }

  #offCanvassearch-small{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 11%;
    gap: 1.5%;
    padding: 2%;
  }

  #offCanvassearch-large {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 20%;
    gap: 1.5%;
    padding: 2%;
  }

 #offCanvassearch{
    width: 100% ;
    background: transparent;
    min-height: calc(100% + var(--inputs-margin-offset) + (2 * var(--inputs-border-width)));
    line-height: calc(1 + .8 / var(--font-body-scale));
    top: 0 ;
   
  }

  .search-container {
    position: relative;
    text-align: center;
    width: 40%;
    display: flex;

    @media screen and (max-width: 39.9375em) {
      width: 100%;

    }

  }

  .search-container input[type="text"] {
    padding: 19px; 
    border: 1px solid #22222200; 
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 2px rgba(10, 10, 10, 0%);
    
  }
    

  .search-input-container {
   
    border: 2.5px solid #222;
    width: 100%;
    height: 100%;
    display: flex;

    img{
      height: 60px;
      padding: 1.5%;
    }
 }

 .search-input-container-small {
   
  border: 2.5px solid #222;
  width: 100%;
  height: 100%;
  display: flex;

  img{
    // height: 38px;
    padding: 1.5%;
  }
}


 


.close-icon {
    content: '';
    display: inline-block;
    width: 18px;
    height: 17px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" class="icon icon-close" fill="none" viewBox="0 0 18 17"><path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z" fill="currentColor"></path></svg>') no-repeat;
}


  .rotate-icon {
    transform: rotate(180deg);
  }

  .off-canvaslarge {
    display: none;
    padding-bottom: 2.4rem;

    ul{

      margin: 0;
      list-style-type: none;

      a{
        color:  $highlight-color;
        :hover{
          text-decoration: underline;
          text-decoration-color: $menu-text-color;
          text-underline-offset: 0.3rem;
          color: $menu-text-color;
          transition: text-decoration var(--duration-short) ease;
          text-decoration-thickness: 0.5px;
          

        }
      }
      p {
        margin-bottom: 0.5rem 
      }

    }
    
  }
  
  .off-canvaslarge.is-open {
    display: block;
  }
  


  #offCanvasSubMenu{
  
    overflow-y: auto;
    background-color: #ffffff;
    height: 100%;
    top: 10%;
   
    ul {

      list-style-type: none;
      padding: 3rem 0rem ;

      li{
        padding: 0.5rem 1rem ;
      }
      
      a{
        color:  $menu-text-color;
        font-size: 0.8rem;
        text-decoration: none;
        -webkit-transition: color 0.2s ease-in;
        transition: color 0.2s ease-in;
        font-size: 19.8px; 
      }
      }
  }
  
 
  
  .underline {
    text-decoration: underline; 
    color: $menu-text-color;
  }

  .off-canvas.position-left {
    ul {
      list-style: none;
      padding: 10% 0;
      margin: 0;
  
      li.about-dropdown {
        position: relative;
  
        a.about-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #333;
          position: relative;
  
          span.header__active-menu-item {
            margin-right: 1rem;
          }
  
          svg.icon-arrow {
            position: absolute;
            height: 0.8rem;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
  
      li#aboutSubMenuLink {
        display: flex;

        span.header__active-menu-item {
          padding: 0 15%;          
        }

        a.about-link {
          
          align-items: center;
          text-decoration: none;
          color: #333; /* Adjust the color as needed */
          position: relative;
  
         
          svg.icon-arrow {
            position: absolute;
            height: 0.8rem;
            left: 0.5rem; /* Adjusted left position */
            top: 50%;
            transform: translateY(-50%) scaleX(-1); /* Mirrored effect */
          }
        }
      }
    }
  }
  
  

 
  
  
  .offcanvasleft .off-canvas {
    position: absolute;
    top: 100%;
    left: 0;
    width: 0; /* Start with 0 width */
    height: 100vh;
    background: white;
    overflow-x: hidden;
    transition: width 0.3s ease-in-out; 
    ul {
      list-style-type: none;
      li{
        padding: 0.5rem 1rem;
      }
      a {
        color: $menu-text-color;
        font-size: 0.8rem;
        text-decoration: none;
        -webkit-transition: color 0.2s ease-in;
        transition: color 0.2s ease-in;
        font-size: 19.8px;
       
      }
    }
    
  }

  
  .menu-icon::after {
    content: '';
    display: inline-block;
    width: 18px;  
    height: 16px; 
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" class="icon icon-hamburger" fill="none" viewBox="0 0 18 16"><path d="M1 .5a.5.5 0 100 1h15.71a.5.5 0 000-1H1zM.5 8a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1A.5.5 0 01.5 8zm0 7a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1a.5.5 0 01-.5-.5z" fill="currentColor"></path></svg>') no-repeat;
  }

  .search-symbol,
  .responsive-nav-social-mobile-right {
    .menu .button {
      background-color: transparent;
      padding: 0;
    }
  }


  .off-canvas.is-transition-overlap.is-open {
    box-shadow: none;
  }

 
  .show-mobile-menu {
    transform: translateX(0%);
  }

  .mobile-menu {
    list-style: none;
    padding: 20px;
  }

  .mobile-menu li {
    margin-bottom: 10px;

    a {
      color: $nav-text-color;
    }
  }

  .responsive-nav-social-mobile {
    background: $nav-bg-color;
    display: flex;
    align-items: center;
    width: 100%;
    // position: fixed;
    z-index: 5;

    .responsive-nav-social-mobile-left {
      flex: 1 0 0;

      li {
        font-size: 1.5rem;

        .fa {
          color: $nav-text-color;
          margin-right: -0.7rem;
        }
      }
    }

    .responsive-nav-social-mobile-right {
      .menu-icon {
        margin-right: $nav-padding;

        li {
          a {
            margin-right: -1.2rem;

            &:hover {
              color: $highlight-color;
            }
          }
        }

        &::after {
          padding-right: 1.5rem;
          background: $menu-text-color; // Change background color
          box-shadow: 0 7px 0 $menu-text-color, 0 14px 0 $menu-text-color; // Change box-shadow color
        }
      }
    }
  }

  .responsive-nav-social {
    background-color: $nav-bg-color;
    

   

    .row.align-justify.align-middle {
      display: flex;
      -webkit-column-gap: 2rem;
      -moz-column-gap: 2rem;
      column-gap: 2rem;
      padding-top: 25px;
      padding-bottom: 36px;

      .logo {
        img {
          height: 42.2px;
          width: 170px;
        }
      }

      .home-link {
        ul.menu.vertical.medium-horizontal {
          display: flex;
      
          li {
            a {
              color: $nav-text-color;
              font-size: 19.8px;
              text-decoration: none;
              transition: color 0.2s ease-in;
      
              &:hover {
                color: $menu-text-color;
                text-decoration: underline;
                text-underline-offset: 0.3rem;
                transition: text-decoration var(--duration-short) ease;
              }
      
              &.active {
                color: $menu-text-color;
                text-decoration: underline;
                text-underline-offset: 0.3rem;
              }
            }
          }
        }
      }
      
      
      
      


      .search-symbol.hide-for-small-only {
        ul.menu {
          li {
            a {
              i.fa {
                font-size: 1.3rem;
                color: $nav-text-color;

                &:hover {
                  color: $highlight-color;
                  transition: color 0.2s ease-in;
                }
              }
            }
          }
        }
      }
    }
  }

  .sticky-container {
    width: 100%;
  }
  .align-justify {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: flex-start;
  }
  .search-symbol {
    margin-left: auto;
  }
  .mobile-menu-container.show-mobile-menu {
    display: block;
  }


  @media screen and (max-width: 63.9375em) {
    .menu {
      li {
        align-self: center;
      }
      a {
        padding: 0;
        text-align-last: right;

        img {
          width: 60%;
        }

        svg {
          width: 60%;
        }
      }
    }
    .responsive-nav-social-mobile {
      padding-left: 4.5%;
      padding-right: 4.5%;
      padding-top: 20px;
      padding-bottom: 20px;

      .responsive-nav-social-mobile-left,
      .responsive-nav-social-mobile-center {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
        flex: 0.5 0 0;
      }

      .responsive-nav-social-mobile-right {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
        flex: 0.5 0 0;

        .menu {
          justify-content: right;
        }
      }
    }

    .responsive-nav-social-mobile-center {
      img {
        width: 145px;
        justify-content: center;
        display: flex;
        margin: 0 auto;
      }
    }
  }



  @media screen and (min-width: 1501px) and (max-width: 1600px) {
    .responsive-nav-social .row.align-justify.align-middle .search-symbol .menu a {
      img {
        width: 75%;
      }
    }
  }

  .responsive-nav-social .row.align-justify.align-middle {
    .search-symbol {
      .menu {
        a {
          padding: 0.7rem 0rem;
        }
      }
    }
  }
}

.footer{
  font-family: $font-body-family;
  font-style: $font-body-style;
  font-weight: $font-body-weight;
  padding-top: 5%;
  
  .three-column-footer-contact-form-container {
    background-color: white;
      
    .three-column-footer-contact-form {
      @include grid-row();
      color:$light-gray;
      padding: rem-calc(60) 0;
  
      a {
        color: $light-gray;
      }
      p,h6{
        color:#121212;
      }

      .footer-left {
        .baseline {
          // display: table-cell;
          // vertical-align: text-top;

          .footer-logo{
              width: 200px;
          }
        }
  
        .contact-details {
          p {
            font-size: 0.8rem;
            margin-bottom: 0;
          }
        }
        

        .input-group {
          padding: 2rem 0 0 0 ;
  
          input{
            border-bottom: 2px solid $medium-gray;
          }
        }
  
        @include breakpoint(medium) {
          @include grid-column(12);
          display: table;
        }
  
        @include breakpoint(921px) {
          @include grid-column(3);
        }
      }
  
      .footer-center {
        text-align: left;
  
        @include breakpoint(medium) {
          @include grid-column(12);
          display: table;
        }
  
        @include breakpoint(921px) {
          @include grid-column(4);
          @include grid-column-position(1);
        }
  
        .baseline {
          display: table-cell;
          vertical-align: top;
        }
  
        .input-group {
          padding: 0.5rem 0 0 0;
  
          input{
            border-bottom: 2px solid $medium-gray;
          }
  
          textarea{
            border-bottom: 2px solid $medium-gray;
          }
        }
      }
  
      .footer-right {
        text-align: right;
  
        .baseline {
          display: table-cell;
          vertical-align: bottom;
          text-align: left;
          vertical-align: top;
        }
  
        h2 {
          color: $white;
        }
  
        .fa {
          padding: 1rem 0 1rem 0.2rem;
        }
  
        @include breakpoint(medium) {
          @include grid-column(12);
          display: table;
        }
  
        @include breakpoint(921px) {
          @include grid-column(3);
        }
      }
    }
  }

  .three-column-footer-contact-form a{
    color: #121212 !important;
  }

      



    
  @media screen and (max-width: 57.5em) {


    .baseline{
      text-align: center;

      .footer-logo{
        width: 200px;
        
      }
      .contact-details{
        text-align: left;
        padding-top: 10%;
      

        p{
          color: #121212;
        }

        h6{
          margin-bottom: 1.5rem;
          color: #121212;
        }
      }
    }




    .footer-center{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .footer-right{
      padding-left: 0 !important;
      padding-right: 0 !important;


        p,a{
          color: #121212BF;
        }
        h6{
          margin-bottom: 1.5rem;
          color: #121212;
        }

      
      
    }
  }

  .sociallink-h{
    text-align: center;
    font-weight: 700;
    color: #121212;
  }

  .footer-block--newsletter {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .list-social{

      display: flex;
      justify-content: center;
      list-style-type: none;
    }

    .list-social__item .icon {

      height: 2rem;
      width: 2rem;

      &:hover {
        
          transform: scale(1.1);
        }
    }
    

    .list-social__link {

      align-items: center;
      display: flex;
      padding: 1.3rem;
    }

    ul{

      margin-bottom: 0;
      margin-left: 0;
    }


    .visually-hidden {

      position: absolute!important;
      overflow: hidden;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      clip: rect(0 0 0 0);
      word-wrap: normal!important;
    }


     a {
      color: #121212 !important;
    } 

  }

}


.homepage {

  font-family: $font-body-family;
  font-style: $font-body-style;
  font-weight: $font-body-weight;
  $hero-height: 70vh;

  
  .hero-section {
    position: relative;
    height: $hero-height;
    overflow: hidden;
    z-index: -1;

    .hero-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    .hero-section-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-shadow: 1px 1px 2px black;
      justify-content: center;
      // padding-top: 10%;
      
      
      img{

        width: 40%;
        display: flex;
        margin: 0 auto;
      }

      .texts{
        
        justify-content: center;
        text-align: center;
        display: grid;
        margin: 5%;
        
        
      }

      .button-group{
        justify-content: center;
        z-index: 1;
      }
    }

    .button{
      background: #144421;
      opacity: 1 !important;
      border-radius: 50px;
      
      font-size: 16.5px;
    }

    
    @media screen and (min-width: 1024px) and (max-width: 1800px) {

     .hero-section-text {
        padding-top: 0;
        width: 100%;
      
        .texts{
        width: 100%;
        margin: 2.5% 0;
        }

        img{
        width: 20%;
        }
      }

    }

    @media screen and (min-width: 639px) and (max-width: 1024px) {

      .hero-section-text {
        padding-top: 0;
        width: 100%;
        // margin: 5% 0;
        
        .texts{
          width: 100%;
          margin: 2.5% 0;
          

          h1{
          font-size: 1.5rem;
          }
        }

        img{
          width: 20%;
        }
      }

    }

    @media screen and (max-width: 639px)  {

      .hero-section-text img{
        width: 70%;
      }
    
      .hero-section-text .texts{
        margin-top: 15%;
        h1{
          width: 200%;
          place-self: center;
        }
      }
  
      .hero-video{
        height: 91%;
      }
  
      .lead{
        width: 184%;
        text-align: center;
        left: -40%;
        position: relative;
  
      }
  
  
    }



  }

  .custommerch {
    text-align: center;
    justify-content: center;
    text-align: -webkit-center;
    padding-bottom: 0px;
  
    .texts {

      max-width: 52rem;
      margin: 46px 0;
    
  

      p{
        color: #121212BF;
      }
      
  
      .weare {
        span {
          strong {
            color: white;
            background: #C29761;
            font-size: 0.9rem;
            padding: 3px 0.7rem 5px;
            border-radius: 5px;
          }
        }
      }
    }
    .image-blocks {
      display: flex;
      justify-content: center; 
      text-align: center; 
      flex-wrap: wrap;
      gap: 1%;
      padding-top: 2.5%;

     
    
      .image-block{
        position: relative;
        margin-bottom: 1.5%;
       
       
    
        img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          display: block;
          object-fit: cover;

         
         
        }
        // &:hover {
        
        //   transform: scale(1.5);
        // }

        
    
        .image-caption {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          color: #fff;
          border-radius: 8px;
          padding: 10px;
          font-weight: bold;
          width: 100%;
          color: white;
          font-size: 1.7rem;
          font-weight: bold;
          text-align: center;
          padding-top: 15rem;
          padding-bottom: 15rem;
        }


        &.overlay:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.45);
          border-radius: 8px;
        }
      }
    
      @media screen  {
        .image-block {
          width: calc(33.333% - 20px);
        }
    
        .image-block img {
          max-width: 100%;
          height: 398px;
        }
      }
    
    
      @media screen and (max-width: 988px) {
        .image-block {
          width: 100%;
        }
      }
    }

    @media screen and ( max-width: 9375em ) {

      .custommerch{
        padding-top: 0;
  
        .texts{
          .weare{
            margin-bottom: 0;
          }
  
          .weare span strong {
            font-size: 0.9rem;
            padding: 3px 0.7rem 5px;
      
          }
          
  
        }
    
          .image-blocks{
            padding-top: 20px;
          }
    
          .custom-brand{
            font-size: 41.5px;
            padding-left: 4.5%;
            padding-right: 4.5%;
            padding-top: 10%;
          }
        }

  
    }

    // .image-blocks {
    //   display: flex;
    //     justify-content: center; 
    //     text-align: center; 
    //     padding-top: 2.5%;
    //     gap: 1%;
    // }
  
    // .image-block {
    //   position: relative;
    //   width: 100%;
    //   margin-bottom: 1.5%;
    //   box-sizing: border-box;
    //   overflow: hidden;
    //   border-radius: 10px;
      
  
    //   img {
    //     width: 100%;
    //       height: auto;
    //       border-radius: 8px;
    //       box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    //       display: block;
    //       object-fit: cover;
    //   }

    //   &:hover img {
        
    //     transform: scale(1.5); /* Adjust the scale factor as needed */
    //   }
  
    //   .overlay {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     background: rgba(0, 0, 0, 0.7);
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     color: white;
    //     padding: 2rem;
    //     height: -webkit-fill-available;

  
    //     h3 {
    //       font-size: 27.5px;
    //       font-weight: bold;
    //       margin: 0;
    //     }
  

    // }}

    
  

  }
  



  .services {
    text-align: center;
    justify-content: center;
    text-align: -webkit-center;
    padding-bottom: 0px;
  
    .texts {
      padding: 2rem 1rem ;
  
      h1 {
        font-size: 40px;
      }
    }
  
    .image-blocks-2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 2%;
    }
  
    .image-block-2 {
      position: relative;
      width: 100%;
      margin-bottom: 1%;
      box-sizing: border-box;
      overflow: hidden;
      border-radius: 10px;
  
      img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: block;
        transition: transform 1s ease;
      }

      &:hover img {
        
        transform: scale(1.5); /* Adjust the scale factor as needed */
      }
  
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 2rem;
  
        h3 {
          font-size: 27.5px;
          font-weight: bold;
          margin: 0;
        }
  
        p {
          font-size: 16px;
          margin: 0;
          text-align: left;
          padding-top: 5%;
          width: 100%;
        }
  
        a {
          text-decoration: none;
          color: white;
          margin-top: 1.5rem;
        }
  
        .link {
          text-decoration: none;
          font-size: inherit;
          margin-top: 1.5rem;
          display: flex;
          width: 100%;
        }
  
        .icon-wrap {
          white-space: nowrap;
        }
  
        .icon-arrow {
          width: 1rem;
        }
      }
    }
  
    .button-group-2 {
      justify-content: center;
  
      a {
        text-decoration: none;
        color: white;
      }
  
      .button-2 {
        background: #144421;
        opacity: 1 !important;
        border-radius: 50px;
        padding: 1.1em 2.3em;
        font-size: 16.5px;
      }
    }
 
  
    @media screen and (max-width: 480px) {
      .image-block-2 {
        width: 100%;
      }
      .button-group-2 {
        padding-top: 4%;
      }
    }
  
    @media screen and (min-width: 991px) {
      .image-block-2 {
        width: calc(34.33% - 20px);
      }
  
    }

    @media screen and (max-width: 990px) {
      .image-blocks-2{
        padding-bottom: 5%;
      }
    }
    
  }
}
  
 


.about {
  --max-margin: 300px; 
  $hero-height: 70vh;
  p {
    margin-bottom: 1rem;
    font-size: inherit;
    line-height: 2;
    font-size: 17.6px;
    text-rendering: optimizeLegibility;
    color: #121212BF;
  }

  .hero-section {
    position: relative;
    
    overflow: hidden;

    img{
      display: flex;
      margin: 0 auto;
    }

    // @media screen and (min-width: 40em) and (max-width: 63.9375em) { padding-top: 9%;}

    @media screen and (max-width:471px) { padding-top: 6%; }

    
  }
  
  .content {
    margin: 0 auto;
    padding: 0 1.5rem;
    .title{
      padding-top: 5%;
    }

  }

  .custom-list {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    text-align: center;
    padding-top: 2%;
  }
 
  .title {
    .main-page-title {
      text-align: center;
    }
  }

  .texts {
    .title-wrapper {
      .title-h2 {
        display: block !important;
        text-align: center;
      }
    }


    

    @media screen and (min-width: 750px) {
      .main-page-title {
        margin-bottom: 4rem;
      }
      .texts {
        padding: 36px 55px;
      }
    }
  }

  


  .button-group {
    justify-content: center;

    a {
      text-decoration: none;
      color: white;
    }

    .button {
      background: #144421;
      opacity: 1 !important;
      border-radius: 50px;
      padding: 1.1em 2.3em;
      font-size: 16.5px;
    }
  }
}

.salvation {
  --max-margin: 300px; 
  $hero-height: 70vh;
  p{
    color: #121212BF;
  }
  h5{
    color: #121212;
  }
  
 
  .card{
    border: none;
  }

  .slider-gutter{
    text-align-last: left;
  }

  .hero-section {
    position: relative;
    
    overflow: hidden;
    img{
      // width: 40%;
      display: flex;
      margin: 0 auto;
    }}
  
  .content-salvation {
    // max-width: var(--page-width);
    // margin: 0 auto;
    // padding: 0 10rem;

    .title{
      padding-top: 5%;
      padding-bottom: 7%;
    }

    @media screen and (min-width: 1024px) {
      margin: 0 calc((100vw - var(--page-width)) / 2);

      @media screen and (min-width: 1920px) {
        margin: 0 var(--max-margin);
      }
    }
  }

  .custom-list {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    text-align: center;
    padding-top: 2%;
}
 
  .title {
    text-align: center;
  }

  .texts {
    .title-wrapper {
      .title-h2 {
        display: block !important;
        text-align: center;
      }
    }


    
    @media screen and (min-width: 750px) {
      .main-page-title {
        margin-bottom: 4rem;
      }
    }
  }

  @media screen and (min-width: 750px) {
    .texts {
      padding: 36px 55px;
    }
  }
}



.people{
  --max-margin: 300px; 
  
  p{
    color: #121212BF;
    font-size: 17.6px;
    margin-bottom: 0.5rem !important;
  }
  h5{
    color: #121212;
  }

  h2{
    text-align: center;
  }
  
 
  .card{
    border: none;
    margin-bottom: 2%;
  }

  .slider-gutter{
    text-align-last: left;
  }

 
  
  .content-section {

    .title{
      padding-top: 5%;
      padding-bottom: 7%;
    }

  }

  .custom-list {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    text-align: left;
    padding-top: 2%;
  }
 
  .title {
    text-align: center;
  }

  .texts {
    .title-wrapper {
      .title-h2 {
        display: block !important;
        text-align: center;
      }
    } 
  }

  .customers{
    .gallery {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
  }
  
  .gallery-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
  }
  
  .gallery-item {
    flex: 0 0 calc(33.3333% - 10px); /* Three columns with 10px gap */
    box-sizing: border-box;
    margin: 0;
    padding: 0 2%;
    width: calc(33.33% - 10px); /* Adjusted width */
    max-width: calc(33.33% - 10px); /* Adjusted max-width */
    height: 200px; /* Adjusted height */
    overflow: hidden;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  /* Media query for small devices */
  @media screen and (max-width: 988px) {
      .gallery-item {
          flex: 0 0 100%; /* Full width for small devices */
          max-width: 100%;
      }

      .gallery-image {
      padding: 2%;
  }
  }
  }



}

.contactus {

  .contactus-box{

    .title{
      h4{
        margin-top:1% ;
        margin-bottom:1% ;
        text-align: center;
      }
    }

    .contact-form {
      max-width: 798px;
      margin: 20px auto;
      padding: 20px;
      border: 2px solid #000;
      background-color: #fff;
      box-sizing: border-box;

      .form-row {
          margin-bottom: 15px;
          display: flex;
          flex-wrap: wrap;
      }

      .form-column {
          flex: 1;
          margin-right: 10px;
      }

      .form-group {
          display: flex;
          flex-direction: column;
          width: 100%;
      }

      label {
          margin-bottom: 5px;
      }

      input, textarea {
          padding: 8px;
          margin-bottom: 10px;
          border: 1px solid #000000;
          border-radius: 4px;
          box-sizing: border-box;
          width: 100%;
          height: 2rem;
      }

      button {
          padding: 10px;
          background-color: #000000;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          width: 100%;
      }

      
  }

  }

  .text-block{
    text-align: center;
    padding-top: 40px;
    padding-bottom: 52px 

  }

  
  
}





.gallery-page {
  box-sizing: border-box;

  .container {
    max-width: 1200px; // Set your desired max-width for the container
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .heading {
    font-family: "Montserrat", Arial, sans-serif;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    padding: 3.5rem 0;
    color: #1a1a1a;

    span {
      display: block;
    }
  }

  .gallery {
    // padding: 5rem 1rem;
    margin: 0 auto;
    max-width: 68rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 0.1fr);
    grid-gap: 0.7rem;
    justify-content: center;
    align-items: center;
  
    
  }

  .gallery-item {
    width: 234.68px;
    height: 179.66px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
  .popup{

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%) scale(0);
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow: hidden;
    transition: 1s;
    opacity: 0;

    .image-container{
      
    position: relative;
    height: 100%;
    left: 0;
    outline: none;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: transform,opacity;
    white-space: normal;
    width: 100%;
    z-index: 99994;
    background: linear-gradient(0deg,rgba(0,0,0,.85) 0,rgba(0,0,0,.3) 50%,rgba(0,0,0,.075) 75.5%,rgba(0,0,0,.037) 82.85%,rgba(0,0,0,.019) 88%,transparent);
    }

    
    .image-description {
      
      bottom: 0;
      color: #eee;
      font-size: 14px;
      font-weight: 400;
      left: 0;
      line-height: 1.5;
      padding-top: 2%;
      pointer-events: none;
      right: 0;
      text-align: center;
      z-index: 99996;
      position: absolute;
      width: 100%; /* Make it full width of the container */
      padding: 10px;

      @media screen and (max-width: 39.9375em) {
        padding-top: 0%;

      }

    }

    .image-number{
      color: #ccc;
      font-size: 13px;
      -webkit-font-smoothing: subpixel-antialiased;
      height: 44px;
      left: 0;
      line-height: 44px;
      min-width: 44px;
      mix-blend-mode: difference;
      padding: 0 10px;
      pointer-events: none;
      top: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 400;
    }
  

    .image-index {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 80px;
      font-weight: 100;
      color: #797979;
      opacity: 0;
    }

    &.active {
      .large-image,
      .image-index {
        opacity: 1;
        transition-delay: 1s;
      }
    }
  

  
  }

  .popup.active{
    transform: translate(-50% , -50%) scale(1);
    opacity: 1;
    z-index: 1000;

  }

  .popup.active .close-btn,
  .popup.active .image-number,
  .popup.active .image-description,
  .popup.active .index,
  .popup.active .large-image,
  .popup.active .arrow-btn {

    opacity: 1;
    transition: opacity  .5;
    transition-delay: 1s;


  }

  .custom-color{
    color: #ccc;
  }

  .top-bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: transparent;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-weight: 300;
    z-index: 1;
  }

  .image-number{
    opacity: 0;

  }

  .toolbar{
    visibility: visible;
    right: 0;
    top: 0;
    background: rgba(30, 30, 30, 0.6);
    position: fixed;


    svg{
      display: block;
      height: 100%;
      overflow: visible;
      position: relative;
      width: 100%;
    }
  }

  .fancy-button{
    fill: #ccc;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
  }

  .arrow-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    border: none;
    background: none;
    opacity: 0;
    background:rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
  }

  .left-arrow{
    left: 10px;
    z-index: 1;
  }

  .right-arrow{
    right: 10px;
    z-index: 1;
    
  }

  .index{
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 80px;
    font-weight: 100;
    color: #797979;
    opacity: 0;

    
  }

  .large-image{
    // margin-top: 5%;
    width: 80%;
    height: 85%;
    object-fit: contain;
    opacity: 0;

  }
  

  .zoomed {
    transition: transform 0.3s ease-in-out;
  }

  .zoomed.zoom-in {
      transform: scale(2.5); /* Adjust the scale factor as needed */
  }

  .play-btn,
  .pause-btn {
      display: inline-block;
      cursor: pointer;
  }


  .pause-btn {
      display: none;
  }



.progress-bar {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}



  @media screen and (max-width:37.5em) {


    .gallery {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }

    .gallery-item{
      width: 100%;
      height: 100%;
    }
    
  }

  @media screen and (min-width :37.5em) and (max-width:47.8125em) {

    .gallery-item{
      width: 185px;
      height: 145px 
    }

  }

  @media screen and (max-width: 39.9375em) {
    .large-image{
      width: 100%;
      position: relative;
      top:1%
    }
  }



}


